<template>
    <div class="job-table">
        <el-table
            v-loading="loading"
            :data="tableData"
            border
            style="width: 100%"
            height="450"
            tooltip-effect="light">
            <el-table-column
                prop="name"
                align="center"
                width="200"
                :show-overflow-tooltip="true"
                label="赛道名称">
            </el-table-column>
            <el-table-column
                prop="path"
                align="center"
                width="300"
                :show-overflow-tooltip="true"
                label="所属赛道路径">
            </el-table-column>
            <el-table-column
                prop="jobCreateCount"
                align="center"
                label="新增职位数">
            </el-table-column>
            <el-table-column
                prop="jobTotalCount"
                align="center"
                label="当前职位数">
            </el-table-column>
            <el-table-column
                prop="jobUpdateCount"
                align="center"
                label="更新职位数">
            </el-table-column>
            <el-table-column
                prop="recommedationCount"
                align="center"
                label="推荐数">
            </el-table-column>
            <el-table-column
                prop="acceptCount"
                align="center"
                label="接受数">
            </el-table-column>
            <el-table-column
                prop="arrangeInterviewCount"
                align="center"
                label="面试数">
            </el-table-column>
            <el-table-column
                prop="offerCount"
                align="center"
                label="offer数">
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'job-table',
    props: {
        tableData: Array,
        loading: Boolean
    },
    data() {
        return {
        }
    }
}
</script>

<style>

</style>