var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "job-table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            height: "450",
            "tooltip-effect": "light",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "name",
              align: "center",
              width: "200",
              "show-overflow-tooltip": true,
              label: "赛道名称",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "path",
              align: "center",
              width: "300",
              "show-overflow-tooltip": true,
              label: "所属赛道路径",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "jobCreateCount",
              align: "center",
              label: "新增职位数",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "jobTotalCount",
              align: "center",
              label: "当前职位数",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "jobUpdateCount",
              align: "center",
              label: "更新职位数",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "recommedationCount",
              align: "center",
              label: "推荐数",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "acceptCount", align: "center", label: "接受数" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "arrangeInterviewCount",
              align: "center",
              label: "面试数",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "offerCount", align: "center", label: "offer数" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }