var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "member-table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            height: "450",
            "tooltip-effect": "light",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "userName",
              align: "center",
              width: "150",
              "show-overflow-tooltip": true,
              label: "成员姓名",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "200",
              "show-overflow-tooltip": true,
              label: "所属团队",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatTeam(scope.row.firmTeams))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "trace",
              align: "center",
              width: "300",
              "show-overflow-tooltip": true,
              label: "赛道",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.formatJobCategoties(scope.row.jobCategories))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "recommedationCount",
              align: "center",
              label: "推荐数",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "推荐准确率" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (scope.row.recommedationAccuracy * 1000) / 10 + "%"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "acceptCount", align: "center", label: "接受数" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "arrangeinterviewCount",
              align: "center",
              label: "面试数",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "offerCount", align: "center", label: "offer数" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }