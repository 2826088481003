<template>
    <div class="member-table">
        <el-table
            v-loading="loading"
            :data="tableData"
            border
            style="width: 100%"
            height="450"
            tooltip-effect="light">
            <el-table-column
                prop="userName"
                align="center"
                width="150"
                :show-overflow-tooltip="true"
                label="成员姓名">
            </el-table-column>
            <el-table-column
                align="center"
                width="200"
                :show-overflow-tooltip="true"
                label="所属团队">
                <template slot-scope="scope">
                    <span>{{ formatTeam(scope.row.firmTeams) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="trace"
                align="center"
                width="300"
                :show-overflow-tooltip="true"
                label="赛道">
                <template slot-scope="scope">
                    <span>{{ formatJobCategoties(scope.row.jobCategories) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="recommedationCount"
                align="center"
                label="推荐数">
            </el-table-column>
            <el-table-column
                align="center"
                label="推荐准确率">
                <template slot-scope="scope">
                    <span>{{ scope.row.recommedationAccuracy * 1000 / 10 + '%' }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="acceptCount"
                align="center"
                label="接受数">
            </el-table-column>
            <el-table-column
                prop="arrangeinterviewCount"
                align="center"
                label="面试数">
            </el-table-column>
            <el-table-column
                prop="offerCount"
                align="center"
                label="offer数">
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'member-table',
    props: {
        tableData: Array,
        loading: Boolean
    },
    data() {
        return {
        }
    },
    methods: {
        formatTeam(firmTeams) {
            let result = [];
            firmTeams && firmTeams.forEach(item => {
                result.push(item.teamName);
            });
            return result.join('、');
        },
        formatJobCategoties(jobCategories) {
            let result = [];
            jobCategories && jobCategories.forEach(item => {
                result.push(item.categoryName);
            });
            return result.join('、');
        }
    }
}
</script>

<style>

</style>