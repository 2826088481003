<template>
    <div class="types-data">
        <div class="types-data-wrapper">
            <div class="types-data-container">
                <h3 class="types-data-title">赛道数据</h3>
                <div class="search-bar">
                    <span class="search-bar-left">人才与职位</span>
                    <div class="search-bar-right">
                        <div class="date-wrap triangle-drop" :class="isFocusJobDate?'triangle-drop-up':'triangle-drop-down'">
                            <el-date-picker
                                v-model="jobDateRange"
                                type="daterange"
                                start-placeholder="开始日期"
                                range-separator="-"
                                end-placeholder="结束日期"
                                size="small"
                                :editable="false"
                                align="right"
                                format="yyyyMMdd"
                                @change="handleJobDate"
                                @focus="handleFocusJobDate"
                                @blur="handleBlurJobDate"
                                :clearable="false"
                                :picker-options="expireTimeOption"
                                popper-class="performance-data-range"
                            ></el-date-picker>
                        </div>
                        <div class="track-level-wrap triangle-drop" :class="isFocusTrackLevel?'triangle-drop-up':'triangle-drop-down'">
                            <el-dropdown trigger="click" @command="handleTrackLevelChange" @visible-change="handleTrackLevelVisible">
                                <span class="el-dropdown-link">{{jobParams.trackLevel == 1 ? '一级赛道' : (jobParams.trackLevel == 2 ? '二级赛道' : '三级赛道')}}</span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :class="jobParams.trackLevel == 1 ? 'dropdown-item-active' :''" command="1">一级赛道</el-dropdown-item>
                                    <el-dropdown-item :class="jobParams.trackLevel == 2 ? 'dropdown-item-active' :''" command="2">二级赛道</el-dropdown-item>
                                    <el-dropdown-item :class="jobParams.trackLevel == 3 ? 'dropdown-item-active' :''" command="3">三级赛道</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div class="all-track-wrap triangle-drop" :class="isFocusTrack?'triangle-drop-up':'triangle-drop-down'">
                            <el-cascader
                                v-model="jobParams.track"
                                :options="jobCategories"
                                :props="{'value': 'id', 'label': 'name', 'emitPath': false}"
                                placeholder="全部赛道"
                                :show-all-levels="false"
                                @change="handleTrackChange"
                                @visible-change="handleTrackVisible"
                            ></el-cascader>
                        </div>
                        <div class="export-wrap" @click="handleExportJobReport">
                            <i v-if="exportTrackLoading" class="el-icon-loading"></i>
                            <font-icon class="candidate-icon" href="#icon-daochu"></font-icon>
                            导出
                        </div>
                    </div>
                </div>
                <job-table :tableData="jobTableData" :loading="jobTableLoading"></job-table>

                <div class="search-bar member-search-bar">
                    <span class="search-bar-left">团队与成员</span>
                    <div class="search-bar-right">
                        <div class="date-wrap triangle-drop" :class="isFocusMemberDate?'triangle-drop-up':'triangle-drop-down'">
                            <el-date-picker
                                v-model="memberDateRange"
                                type="daterange"
                                start-placeholder="开始日期"
                                range-separator="-"
                                end-placeholder="结束日期"
                                size="small"
                                :editable="false"
                                align="right"
                                format="yyyyMMdd"
                                @change="handleMemberDate"
                                @focus="handleFocusMemberDate"
                                @blur="handleBlurMemberDate"
                                :clearable="false"
                                :picker-options="expireTimeOption"
                                popper-class="performance-data-range"
                            ></el-date-picker>
                        </div>
                        <div class="employee-select-wrap triangle-drop" :class="teamMemberPopoverVisible?'triangle-drop-up':'triangle-drop-down'">
                            <el-popover
                                popper-class="team-member-popover types-team-member-popover"
                                placement="bottom-end"
                                width="700"
                                trigger="click"
                                v-model="teamMemberPopoverVisible"
                                >
                                <team-member-select
                                    ref="teamSelect"
                                    :isAllMembers="true"
                                    :isShowDimission="false"
                                    :date="memberDateRange"
                                    :isHistoryData="true"
                                    :visible="teamMemberPopoverVisible"
                                    :selected="memberParams.userIds"
                                    @item-select-cb="employeeSelect"
                                ></team-member-select>
                                <div slot="reference" class="employee-select-text">{{selectedMembersText}}</div>
                            </el-popover>
                        </div>
                        <div class="export-wrap" @click="handleExportMemberReport">
                            <i v-if="exportMemberLoading" class="el-icon-loading"></i>
                            <font-icon class="candidate-icon" href="#icon-daochu"></font-icon>
                            导出
                        </div>
                    </div>
                </div>
                <member-table :tableData="memberTableData" :loading="memberTableLoading"></member-table>
            </div>
        </div>

        <div class="types-data-footer">
            猎必得 liebide.com
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import jobCategoryService from '#/js/service/jobCategoryService.js';
import TeamMemberSelect from '#/component/common/team-member-select.vue';
import JobTable from './layout/jobTable.vue';
import MemberTable from './layout/memberTable.vue';
export default {
    name: 'TypesData',
    components: {
        TeamMemberSelect,
        JobTable,
        MemberTable
    },
    data() {
        return {
            teamMemberPopoverVisible: false,
            selectedMembersText: '全部成员',
            jobDateRange: [],
            jobParams: {
                startDate: '',
                endDate: '',
                trackLevel: 1,
                track: [0]
            },
            jobTableLoading: false,
            jobTableData: [],
            isFocusJobDate: false,
            isFocusTrackLevel: false,
            isFocusTrack: false,
            exportTrackLoading: false,
            memberDateRange: [],
            memberParams: {
                startDate: '',
                endDate: '',
                userIds: []
            },
            memberTableLoading: false,
            memberTableData: [],
            exportMemberLoading: false,
            expireTimeOption: {
                disabledDate(date) {
                    return date.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '昨天',
                    onClick(picker) {
                        const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '今天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '最近7天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近30天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '本月',
                    onClick(picker) {
                        const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                        const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '上月',
                    onClick(picker) {
                        const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                        const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '今年',
                    onClick(picker) {
                        const start = moment().year() + '-01-01';
                        const end = moment().format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            isFocusMemberDate: false,//用于控制日期的三角
            oneLevelJobCategories: [],
            twoLevelJobCategories: [],
            threeLevelJobCategories: []
        }
    },
    computed: {
        jobCategories() {
            if(this.jobParams.trackLevel == 1) {
                return this.oneLevelJobCategories;
            }else if(this.jobParams.trackLevel == 2) {
                return this.twoLevelJobCategories;
            }else {
                return this.threeLevelJobCategories;
            }
        },
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isAdministrator() {
            return this.userInfo.isAdministrator;
        },
        isTrackData() {
            return this.userInfo.privilegeCodeList.includes("TrackData");
        },
    },
    mounted() {
        if(this.isAdministrator || this.isTrackData) {
            this.getCategoryTree();
            let yestoday = moment(new Date()).add(-1, 'days');
            this.jobDateRange = this.memberDateRange = [yestoday,yestoday];
            this.jobParams = Object.assign({},this.jobParams,{
            startDate: this.jobDateRange[0].format('YYYY-MM-DD'),
            endDate: this.jobDateRange[1].format('YYYY-MM-DD')
            });
            this.memberParams = Object.assign({},this.memberParams,{
            startDate: this.memberDateRange[0].format('YYYY-MM-DD'),
            endDate: this.memberDateRange[1].format('YYYY-MM-DD')
            });
            this.getJobTableData();
            this.memberTableLoading = true;
            this.$refs.teamSelect.getHistoryTeamData(true);
        } else {
            shortTips("权限不足，请联系管理员")
            // let tabItems = JSON.parse(sessionStorage.getItem("tabItems"));
            // let _index = tabItems.findIndex(item => item.enTitle === "Types Data");
            // tabItems.splice(_index, 1);
            // sessionStorage.setItem('tabItems', JSON.stringify(tabItems));
            sessionStorage.setItem('tabItems', JSON.stringify([]));
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000)
        }
    },
    methods: {
        formatLeafNode(arr) {
            arr.forEach(item =>{
                if(item.children && item.children.length == 0) {
                    delete item.children;
                }
                if(item.children && item.children.length > 0) {
                    this.formatLeafNode(item.children);
                }
            })
        },
        getCategoryTree() {
            jobCategoryService.getJavaJobCategories()
                .then(res =>{
                    let oneLevelArr = JSON.parse(JSON.stringify(res)),
                        twoLevelArr = JSON.parse(JSON.stringify(res)),
                        threeLevelArr = JSON.parse(JSON.stringify(res));
                    oneLevelArr.forEach(item => {
                        delete item.children
                    });
                    twoLevelArr.forEach(item => {
                        if(item.children && item.children.length == 0) {
                            delete item.children;
                        }
                        if(item.children && item.children.length > 0) {
                            item.children.forEach(child => {
                                delete child.children;
                            })
                        }
                    })
                    this.formatLeafNode(threeLevelArr);
                    this.oneLevelJobCategories = [{id: 0, name: '全部赛道'}].concat(oneLevelArr);
                    this.twoLevelJobCategories = [{id: 0, name: '全部赛道'}].concat(twoLevelArr);
                    this.threeLevelJobCategories = [{id: 0, name: '全部赛道'}].concat(threeLevelArr);
                })
        },
        getJobTableData() {
            this.jobTableLoading = true;
            let jobCategoryId = Array.isArray(this.jobParams.track) ? this.jobParams.track[0] : this.jobParams.track;
            jobCategoryService.getTrackReportList({
                startDate: this.jobParams.startDate,
                endDate: this.jobParams.endDate,
                jobCategoryLevel: this.jobParams.trackLevel,
                jobCategoryId: jobCategoryId,
            }).then(res =>{
                this.jobTableData = res;
                let scrollArea = document.querySelector('.job-table .el-table__body-wrapper');
                if(scrollArea) {
                    scrollArea.scrollTop = 0;
                }
            }).finally(() => {
                this.jobTableLoading = false;
            })
        },
        getMemberTableData() {
            this.memberTableLoading = true;
            jobCategoryService.getMemberReportList({
                startDate: this.memberParams.startDate,
                endDate: this.memberParams.endDate,
                userIds: this.memberParams.userIds
            }).then(res =>{
                this.memberTableData = res;
                let scrollArea = document.querySelector('.member-table .el-table__body-wrapper');
                if(scrollArea) {
                    scrollArea.scrollTop = 0;
                }
            }).finally(() => {
                this.memberTableLoading = false;
            })
        },
        handleJobDate(value){
            this.jobParams.startDate = moment(value[0]).format('YYYY-MM-DD');
            this.jobParams.endDate = moment(value[1]).format('YYYY-MM-DD');
            this.getJobTableData();
        },
        handleFocusJobDate(){
            this.isFocusJobDate = true;
        },
        handleBlurJobDate(){
            this.isFocusJobDate = false;
        },
        handleMemberDate(value){
            this.memberParams.startDate = moment(value[0]).format('YYYY-MM-DD');
            this.memberParams.endDate = moment(value[1]).format('YYYY-MM-DD');
            this.memberTableLoading = true;
            this.$refs.teamSelect.getHistoryTeamData(true);
        },
        handleFocusMemberDate(){
            this.isFocusMemberDate = true;
        },
        handleBlurMemberDate(){
            this.isFocusMemberDate = false;
        },
        handleTrackLevelChange(command) {
            this.jobParams.trackLevel = parseInt(command);
            this.jobParams.track = 0;
            this.getJobTableData();
        },
        handleTrackLevelVisible(visible) {
            this.isFocusTrackLevel = visible;
        },
        handleTrackVisible(visible) {
            this.isFocusTrack = visible;
        },
        handleTrackChange(e) {
            this.getJobTableData();
        },

        employeeSelect(userIds, text) {
            this.memberParams.userIds = userIds;
            this.selectedMembersText = text;
            this.getMemberTableData();
            this.teamMemberPopoverVisible = false;
        },

        handleExportJobReport() {
            if(this.exportTrackLoading) {
                return false;
            }
            this.exportTrackLoading = true;
            let jobCategoryId = Array.isArray(this.jobParams.track) ? this.jobParams.track[0] : this.jobParams.track;
            jobCategoryService.exportTrackReportList({
                startDate: this.jobParams.startDate,
                endDate: this.jobParams.endDate,
                jobCategoryLevel: this.jobParams.trackLevel,
                jobCategoryId: jobCategoryId,
            }).then(res =>{
                if(res.status == 200) {
                    this.downloadFile(res);
                }else {
                    shortTips('网络错误，请稍后重试');
                }
            }).catch(err =>{
                shortips('网络错误，请稍后重试');
            }).finally(() => {
                this.exportTrackLoading = false;
            })
        },
        handleExportMemberReport() {
            if(this.exportMemberLoading) {
                return false;
            }
            this.exportMemberLoading = true;
            jobCategoryService.exportMemberReportList({
                startDate: this.memberParams.startDate,
                endDate: this.memberParams.endDate,
                userIds: this.memberParams.userIds
            }).then(res =>{
                if(res.status == 200) {
                    this.downloadFile(res);
                }else {
                    shortTips('网络错误，请稍后重试');
                }
            }).catch(err =>{
                shortTips('网络错误，请稍后重试');
            }).finally(() => {
                this.exportMemberLoading = false;
            })
        },
        downloadFile(res) {
            const fileName = res.headers['content-disposition'].match(/filename=(.*)/)[1] + '.xlsx';
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            if (typeof window.navigator.msSaveBlob !== 'undefined') {
                // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
                window.navigator.msSaveBlob(blob, decodeURI(fileName))
            } else {
                // 创建新的URL并指向File对象或者Blob对象的地址
                const blobURL = window.URL.createObjectURL(blob)
                // 创建a标签，用于跳转至下载链接
                const tempLink = document.createElement('a')
                tempLink.style.display = 'none'
                tempLink.href = blobURL
                tempLink.setAttribute('download', decodeURI(fileName))
                // 兼容：某些浏览器不支持HTML5的download属性
                if (typeof tempLink.download === 'undefined') {
                    tempLink.setAttribute('target', '_blank')
                }
                // 挂载a标签
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
                // 释放blob URL地址
                window.URL.revokeObjectURL(blobURL)
            }
        }
    }
}
</script>

<style lang="scss">
.team-member-popover.types-team-member-popover {
    transform: translateX(0);
}
.types-data {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
    background: url('~@src/assets/images/home/home_bg.png') bottom center no-repeat;

    box-sizing: border-box;
    padding: 20px 10px 20px 20px;

    &-wrapper {
        flex-grow: 1;
    }

    &-container {
        padding: 0 20px 20px;
        background-color: #fff;
        border-radius: 8px;
    }

    &-title{
        font-size: 24px;
        color: #333;
        font-weight: bold;
        line-height: 80px;
    }

    .search-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &-left {
            font-size: 20px;
            color: #333;
            line-height: 60px;
        }

        &-right {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    .member-search-bar {
        margin-top: 60px;
    }

    .date-wrap{
        position: relative;
        display: inline-block;
        cursor: pointer;

        &:hover{
            .el-date-editor--daterange.el-input__inner{
                border-color:#38BC9D;
                background: #38BC9D;
                .el-range-input{
                    background: #38BC9D;
                    color: #fff;
                }
                .el-range-separator{
                    color: #fff;
                }
            }
        }
        .el-range-editor.is-active{
            background: #38BC9D;
            .el-range-input{
                background: #38BC9D;
                color: #fff;
            }
            .el-range-separator{
                color: #fff;
            }
        }
        &.triangle-drop-up{
            &::after{
                border-bottom: 12px solid #fff;
            }
        }
        .el-date-editor--daterange.el-input__inner{
            width: 190px;
            border-radius: 16px;
        }
        .el-date-editor .el-range__icon{
            display: none;
        }

        .el-date-editor .el-range__close-icon{
            display: none;
        }
    }

    .triangle-drop{
        &:after{
            position: absolute;
            display: block;
            content: '';
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
            pointer-events: none;
        }
        &-down{
            &:after{
                border-top: 12px solid #ddd;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
            }
            &:hover{
                &:after{
                    border-top: 12px solid #fff;
                }
            }

        }
        &-up{
            &:after{
                border-bottom: 12px solid #ddd;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
            }
            &:hover{
                &:after{
                    border-bottom: 12px solid #fff;
                }
            }
        }
    }

    .track-level-wrap, .all-track-wrap {
        position: relative;
        display: inline-block;
        height: 32px;
        line-height: 32px;
        padding-left: 16px;
        margin-left: 20px;
        padding: 0 10px;
        border: 1px solid #DCDFE6;
        border-radius: 16px;

        .el-input__inner::placeholder {
            color: #666;
        }

        &:hover{
            color: #fff;
            border-color:#38BC9D;
            background: #38BC9D;

            .el-dropdown-link, .el-input__inner {
                color: #fff;

                &::placeholder {
                    color: #fff;
                }
            }
        }

        .el-dropdown-link {
            padding-right: 30px;
        }

        &.triangle-drop-up{
            background: #38BC9D;
            border-color: #38BC9D;
            .el-dropdown-link, .el-input__inner {
                color: #fff;
            }
            &::after{
                border-bottom: 12px solid #fff;
            }

            .el-input__inner::placeholder {
                color: #fff;
            }
        }
    }
    .export-wrap {
        width: 122px;
        height: 32px;
        margin-left: 20px;
        line-height: 32px;
        text-align: center;
        border-radius: 16px;
        border: 1px solid #DCDFE6;
        cursor: pointer;

        .el-icon-loading {
            margin-right: 10px;
            font-size: 16px;
        }

        .font-icon {
            margin-right: 6px;
        }

        &:hover {
            color: #fff;
            border-color:#38BC9D;
            background: #38BC9D;

            .font-icon {
                color: #fff;
            }
        }
    }

    .employee-select-wrap{
        position: relative;
        margin-left: 20px;

        .employee-select-text {
            display: inline-block;
            width: 116px;
            height: 32px;
            line-height: 32px;
            background-color: #fff;
            border: 1px solid #d3d3d3;
            border-radius: 16px;
            padding: 0 36px 0 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: top;
        }

        &:hover{
            .employee-select-text{
                background: #38BC9D;
                border-color: #38BC9D;
                color: #fff;
            }

        }
        &.triangle-drop-up{
            &::after{
                border-bottom: 12px solid #fff;
                margin-top: -3px;
            }
            .employee-select-text{
                background: #38BC9D;
                border-color: #38BC9D;
                color: #fff;
            }
        }
    }

    .el-dropdown {
        width: 100px;
    }

    .el-cascader {
        width: 100px;
        height: 30px;
        line-height: 30px;

        .el-input__inner {
            height: 30px;
            line-height: 30px;
            padding: 0;
            padding-right: 30px;
            vertical-align: top;
            border: 0 none;    
            background-color: transparent;

            &:hover {
                color: #fff;
            }
        }

        .el-input__suffix {
            display: none;
        }
    }

    &-footer {
        height:45px;
        line-height: 45px;
        text-align: center;
        font-size: 12px;
        color: #999999;
    }
}
</style>
<style lang="scss">
.el-cascader-menu {
    width: 180px;
}
.el-dropdown-menu__item.dropdown-item-active {
    color: $primary;
}
</style>